<app-c-header></app-c-header>
<section>
    <div class="container">
        <h5 class="titulo-carrinho">Meu Pedido</h5>
        <div class="row" *ngFor="let product of _order.products; let i=index">
            <div class="col-10">
                <p class="titulo-item">{{product.name}}</p>
                <p>
                    <span>1x {{product.name}}</span>
                    <span> {{getFormattedPrice(product.price)}} </span>
                    <span *ngFor="let group of product.group">
                        <span *ngFor="let complement of group.complement">
                            <span *ngIf="complement.qtdTotal > 0">+ {{complement.name}} ({{complement.qtdTotal}}x {{getFormattedPrice(complement.qtdTotal * complement.price)}})
                            </span>
                        </span>
                    </span>
                    <span>= {{getFormattedPrice(getTotalProduct(product))}}</span>
                    <br>
                    <span>{{product.obs}}</span>
                </p>
            </div>
            <div class="col-2 icone-lixo">
                <i (click)="apagarItem(i)"class="fa fa-trash" aria-hidden="true"></i>
            </div>
        </div>
        <hr>
        <div class="col-12">
            <a href="/checkout">
                <button type="button" class="btn btn-success envia" style="width: 100%;"><i _ngcontent-osw-c19="" aria-hidden="true" class="fa fa-whatsapp fa-1x"></i><span style="margin-left: 5px;">Finalizar Pedido</span></button>
            </a>
        </div>
    </div>
</section>