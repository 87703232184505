import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class MenuServiceService {
  private isHome = new Subject<boolean>();
  private valorTotal = new Subject<number>();

  constructor() {
    this.isHome.next(false);
    this.valorTotal.next(0);
   }

   updateIsHome(state: boolean) {
    this.isHome.next(state);
  }

  updateValorTotal(valor: number) {
    this.valorTotal.next(valor);
  }

  changeIsHome(): Observable<boolean> {
    return this.isHome.asObservable();
  }

  changeValorTotal(): Observable<number> {
    return this.valorTotal.asObservable();
  }
}
