import { Component, OnInit, Input } from '@angular/core';
import { MenuServiceService } from 'src/app/services/menu-service.service';
import { Product } from 'src/app/models/Product';
import { Order } from 'src/app/models/Order';
import { Router } from "@angular/router"
@Component({
  selector: 'app-c-card-footer',
  templateUrl: './c-card-footer.component.html',
  styleUrls: ['./c-card-footer.component.scss']
})
export class CCardFooterComponent implements OnInit {
  @Input() product: Product;
  public isHome: boolean = false;
  public valorTotal: number;
  public valorTotalStr: string;
  public totalGeralTodosItens: string;
  public quantidadeGeralTodosItens: number;
  constructor(private menuService: MenuServiceService, private router: Router) { }

  ngOnInit(): void {
    this.configurarFooter();

    let localStorage = window.localStorage;
    let order = localStorage.getItem('order');

    if (order != null) {
      let jsonObj: any = JSON.parse(order);
      let orderObj: Order = <Order>jsonObj;
      this.totalGeralTodosItens = this.getFormattedPrice(this.calculaTotal(orderObj.products));
      this.quantidadeGeralTodosItens = orderObj.products.length;
    }
  }

  configurarFooter() {
    this.menuService.changeIsHome().subscribe(result => {
      this.isHome = result;
    });

    this.menuService.changeValorTotal().subscribe(result => {
      this.valorTotal = result;
      this.valorTotalStr = this.getFormattedPrice(result);
    });
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  addProdutoCarrinho() {
    let localStorage = window.localStorage;
    let order = localStorage.getItem('order');

    if (order == null) {
      let productsArr: Product[] = [];
      productsArr.push(this.product);

      const newOrder: Order = {
        products: productsArr,
      }
      localStorage.setItem('order', JSON.stringify(newOrder))
    } else {
      let jsonObj: any = JSON.parse(order);
      let orderObj: Order = <Order>jsonObj;
      orderObj.products.push(this.product);
      localStorage.setItem('order', JSON.stringify(orderObj))
    }

    this.router.navigate(['/']);
  }

  calculaTotal(productArr: Product[]): number {
    let totalGeral = 0;
    productArr.forEach(product => {
      totalGeral += product.price;
      product.group.forEach(group => {
        group.complement.forEach(complement => {
          if (complement.qtdTotal > 0) {
            totalGeral += complement.qtdTotal * complement.price;
          }
        });
      });
    });

    return totalGeral;
  }


}
