import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { OrderRequest } from '../models/OrderRequest';

@Injectable({
  providedIn: 'root'
})

export class OrderService {
  url = 'https://ph-cardapio.herokuapp.com';
  constructor(private httpClient: HttpClient) { }
    

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  }

  postOrder(orderRequest: OrderRequest) {
    this.httpClient.post<OrderRequest>(this.url+'/api/order', orderRequest)
      .subscribe(data => {
        return data;
      });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
    } else {
      // Erro ocorreu no lado do servidor
      errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  };

}

