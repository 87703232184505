import { Component, OnInit } from '@angular/core';
import { ProdutoService } from '../services/produto.service';
import { ActivatedRoute } from '@angular/router';
import { Product } from '../models/Product';
import { MenuServiceService } from '../services/menu-service.service';
declare var jQuery: any;
@Component({
  selector: 'app-produto-detalhe',
  templateUrl: './produto-detalhe.component.html',
  styleUrls: ['./produto-detalhe.component.scss']
})
export class ProdutoDetalheComponent implements OnInit {
  product: Product;
  baseImageUrl = 'https://safood.blob.core.windows.net/produtos';
  companyId = 1;
  productLoad = false;
  
  constructor(private route: ActivatedRoute, private produtoService: ProdutoService, private menuService: MenuServiceService,) { }

  ngOnInit(): void {
    (function ($) {
      $('.btn-back').click(function () {
        window.history.back();
      });
    })(jQuery);


    this.route.paramMap.subscribe(params => {
      let id = this.route.snapshot.params.id;
      if (id != null && id !== typeof ('undefined')) {
        this.getProduct(id);
      }
    });
  }

  getProduct(id) {
    this.produtoService.getProductById(id).subscribe((product: Product) => {
      this.product = product;
      this.product.obs = '';
      for (let group of this.product.group) {
        for (let complement of group.complement) {
          complement.qtdTotal = 0;
          this.productLoad = true;
        }
      }
      this.atualizaValorFooter();
    });
  }

  changeQtdComplementItem(event: any, type: number) {
    let groupId = event.target.getAttribute('data-group');
    let complementId = event.target.getAttribute('data-complement');

    if (type === 0) {
      if (this.product.group.find(x => x.id == groupId).complement.find(c => c.id == complementId).qtdTotal > 0) {
        this.product.group.find(x => x.id == groupId).complement.find(c => c.id == complementId).qtdTotal--;
      }
    } else if (type === 1) {
      this.product.group.find(x => x.id == groupId).complement.find(c => c.id == complementId).qtdTotal++;
    }
    this.atualizaValorFooter();
  }

  atualizaValorFooter(){
    let totalGeral = this.calculaTotal();
    setTimeout(() => {
      this.menuService.updateValorTotal(totalGeral);
    }, 0);
  }
  calculaTotal() : number{
    let totalProduto = this.product.price;
    let totalGeral = 0;

    this.product.group.forEach(group => {
      group.complement.forEach(complement => {
         if(complement.qtdTotal > 0){
          totalGeral += complement.qtdTotal * complement.price;
         }
      });
    });

    console.log('Total Geral: R$' + (totalProduto + totalGeral));

    return totalProduto + totalGeral;
  }

  setObs($event){
    var data = $event.target.value;
    this.product.obs = data;
  }


}
