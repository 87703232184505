
<div *ngIf="isHome && quantidadeGeralTodosItens > 0"  class="carrinho">
    <div class="row">
        <div class="col-6 c1">
            <a href="/carrinho" style="color: #FFF;">
                <p><span>{{totalGeralTodosItens}}</span><span> ({{quantidadeGeralTodosItens}} item(s))</span></p>
            </a>
        </div>
        <div class="col-6 c2">
            <a href="/checkout">
                <p class="add-cart">Finalizar Pedido</p>
            </a>
        </div>
    </div>
</div>

<div *ngIf="!isHome" class="carrinho">
    <div class="row">
        <div class="col-3 c1">
            <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        </div>
        <div class="col-9 c2">
            <p *ngIf="valorTotal == 0" class="add-cart">SELECIONE UM PRODUTO</p>
            <p *ngIf="valorTotal > 0"(click)="addProdutoCarrinho()" class="add-cart">ADICIONAR POR {{valorTotalStr}}</p>
        </div>
    </div>
</div>