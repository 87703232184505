<app-c-header></app-c-header>
<div class="banner text-center">
    <div class="c-imagem"><img src="assets/images/lacasa.jpg" class="img-fluid logo"></div>
</div>
<div class="horario">
    <div class="c-horario" *ngIf="online != null">
        <p style="margin: 0 auto;text-align: center;padding-top: 25px;">
            <span *ngIf="online">Online</span>
            <span *ngIf="!online">Offline</span>
            <span class="dot" [ngClass]="online ? 'green' : 'red' "></span>
        </p>
        <p style="text-align: center; cursor: pointer;" data-toggle="modal" data-target="#exampleModal">Horário de funcionamento</p>
    </div>
</div>
<nav id="nav-top" class="navbar navbar-expand-sm navbar-dark">
    <div class="container">
        <ul class="navbar-nav">

            <li *ngFor="let categoria of categorias" class="nav-item">
                <a class="nav-link" href="#{{categoria.name | lowercase }}">{{categoria.name}}</a>
            </li>
        </ul>
    </div>
</nav>

<div *ngFor="let categoria of categorias" class="container-fluid secao" id="{{categoria.name | lowercase }}">
    <div class="container">
        <h1>{{categoria.name}}</h1>
        <div class="row">
            <div *ngFor="let product of categoria.products" class="col-12 col-sm-12" style=" padding-bottom: 20px; margin-bottom: 25px; font-size: 14px; border-bottom: 1px solid #d5d5d5;">
                <a href="/produto/{{product.id}}">

                    <h5 class="card-title">{{product.name}}</h5>
                    <div class="" style="float: left;margin-right: 20px;">
                        <img class="card-img-top" src="{{baseImageUrl}}/{{companyId}}/{{product.image}}" alt="{{product.name}}" style="width: 130px; height: 150px;">
                

                    </div>
                    <div class="">
                        <p>{{product.desc}}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
<app-c-card-footer></app-c-card-footer>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Horário de Funcionamento</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="companyLoad">
          <p *ngIf="company.mondayStart != '--'">segunda-feira das <span>{{company.mondayStart}} ás {{company.mondayStop}}</span></p>
          <p *ngIf="company.mondayStart == '--'">segunda-feira das <span>--</span></p>
          <p *ngIf="company.tuesdayStart != '--'">terça-feira das <span>{{company.tuesdayStart}} ás {{company.tuesdayStop}}</span></p>
          <p *ngIf="company.tuesdayStart == '--'">terça-feira das <span>--</span></p>
          <p *ngIf="company.wednesdayStart != '--'">quarta-feira das <span>{{company.wednesdayStart}} ás {{company.wednesdayStop}}</span></p>
          <p *ngIf="company.wednesdayStart == '--'">quarta-feira das <span>--}</span></p>
          <p *ngIf="company.thursdayStart != '--'">quinta-feira das <span>{{company.thursdayStart}} ás {{company.thursdayStop}}</span></p>
          <p *ngIf="company.thursdayStart == '--'">quinta-feira das <span>--</span></p>
          <p *ngIf="company.fridayStart != '--'">sexta-feira das <span>{{company.fridayStart}} ás {{company.fridayStop}}</span></p>
          <p *ngIf="company.fridayStart == '--'">sexta-feira das <span>--</span></p>
          <p *ngIf="company.saturdayStart != '--'">sábado das <span>{{company.saturdayStart}} ás {{company.saturdayStop}}</span></p>
          <p *ngIf="company.saturdayStart == '--'">sábado das <span>--</span></p>
          <p *ngIf="company.sundayStart != '--'">domingo das <span>{{company.sundayStart}} ás {{company.sundayStop}}</span></p>
          <p *ngIf="company.sundayStart == '--'">domingo das <span>--</span></p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>