import { Component, OnInit } from '@angular/core';
import { Address } from '../models/Address';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order } from '../models/Order';
import { Router } from '@angular/router';
import { OrderService } from '../services/order.service';
import { OrderRequest } from '../models/OrderRequest';
import { OrderProduct } from '../models/OrderProduct';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})


export class CheckoutComponent implements OnInit {
  _order: Order;
  localStorage = window.localStorage;
  address: Address = new Address();
  formGroup: FormGroup;
  sucesso : boolean = true;

  constructor(private formBuilder: FormBuilder, private router: Router, private orderService : OrderService) {
  }

  ngOnInit(): void {

    let order = localStorage.getItem('order');
    let myaddress = localStorage.getItem('my-address');

    if (order == null) {
      this.router.navigate(['/']);
    }

    let jsonObj: any = JSON.parse(order);
    this._order = <Order>jsonObj;
    if (myaddress != null) {
      let jsonObjA: any = JSON.parse(myaddress);
      let myAd = <Address>jsonObjA;
      this.formGroup = this.formBuilder.group({
        Name: [myAd.Name, Validators.required],
        Celular: [myAd.Cel, Validators.required],
        Cep: [myAd.PostalCode, Validators.required],
        Rua: [myAd.Street, Validators.required],
        Numero: [myAd.Number, Validators.required],
        Complemento: [myAd.Complement, Validators.required],
        Pagamento: [myAd.PaymentType, Validators.required],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        Name: ['', Validators.required],
        Celular: ['', Validators.required],
        Cep: ['', Validators.required],
        Rua: ['', Validators.required],
        Numero: ['', Validators.required],
        Complemento: ['', Validators.required],
        Pagamento: ['', Validators.required],
      });
    }
  }

  onSubmit(formData) {
    if (this.formGroup.valid) {
      this.address.Name = formData['Name'];
      this.address.Cel = formData['Celular'];
      this.address.PostalCode = formData['Cep'];
      this.address.Street = formData['Rua'];
      this.address.Number = formData['Numero'];
      this.address.Complement = formData['Complemento'];
      this.address.PaymentType = formData['Pagamento'];

      this._order.Address = this.address;
      let jsonAddressObj: any = JSON.stringify(this.address);
      localStorage.setItem('my-address', jsonAddressObj);

      let textWhats = this.generateOrderString();
      console.log(textWhats);

      //CAL API BACK
      let a = this.orderService.postOrder(this.getOrderRequest());
      
      //CLEAN CARD
      localStorage.removeItem('order');


      window.location.href = "https://api.whatsapp.com/send?phone=+5516991017754&text=" + window.encodeURIComponent(textWhats);
    }else{
      this.sucesso = false;
    }
  }

  getOrderRequest() : OrderRequest {

    let orderP : OrderProduct[] = [];
    this._order.products.forEach(x => {
      orderP.push({
        ProductId : x.id,
        Descricao : x.desc,
        Quantidade : 1,
        ValorTotal : x.price,
        ValorUnitario : x.price,
      })
    })

    let orderR : OrderRequest = {
      Nome : this.address.Name,
      Celular : this.address.Cel,
      CompanyId : "1",
      Cep : this.address.PostalCode,
      Rua : this.address.Street,
      Numero : this.address.Number,
      Complemento : this.address.Complement,
      Pagamento : this.address.PaymentType,
      OrderProduct : orderP
    };

    return orderR;
  }

  generateOrderString(): string {
    let totalGeral = 0;
    let line_space = "----------------------------";
    let strDesc = "Pedido \n " + line_space + " \n \n";

    this._order.products.forEach(product => {
      let totalProduto = 0;
      strDesc += "1x " + product.name;
      strDesc += ' ' + this.getFormattedPrice(product.price);
      totalGeral += product.price;
      totalProduto += product.price;
      product.group.forEach(group => {
        group.complement.forEach(complement => {
          if (complement.qtdTotal > 0) {
            strDesc += ' + ' + complement.name;
            if (complement.price > 0) {
              strDesc += ' (' + complement.qtdTotal + 'x ' + this.getFormattedPrice(complement.price) + ')';
            }
            totalGeral += complement.qtdTotal * complement.price;
            totalProduto += complement.qtdTotal * complement.price;
          }
        });
      });
      strDesc += ' = ' + this.getFormattedPrice(totalProduto) + '\n';
      strDesc += product.obs + '\n \n';
    });
    strDesc += 'Total: ' + this.getFormattedPrice(totalGeral) + '\n' + line_space + '\n';

    if (this._order.Address?.Name != null) {
      strDesc += this._order.Address.Name + '\n';
    }

    if (this._order.Address?.Cel != null) {
      strDesc += this._order.Address.Cel + '\n \n';
    }

    if (this._order.Address?.Street != null) {
      strDesc += this._order.Address.Street;
    }

    if (this._order.Address?.Number != null) {
      strDesc += ', ' + this._order.Address.Number;
    }

    if (this._order.Address?.Complement != null) {
      strDesc += ', ' + this._order.Address.Complement;
    }

    if (this._order.Address?.PostalCode != null) {
      strDesc += '\n' + this._order.Address.PostalCode;
    }

    if (this._order.Address?.PaymentType != null) {
      if (this._order.Address.PaymentType == 2) {
        strDesc += '\n \nPagamento: Cartão \n';
      } else if (this._order.Address.PaymentType == 1) {
        strDesc += '\n \nPagamento: Dinheiro \n' + line_space;
      }
    }
    return strDesc;
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

}
