<app-c-header></app-c-header>

<div class="container top-r" *ngIf="productLoad">
    <div class="row">
        <div class="col-sm-12 p0 text-center">
            <img src="{{baseImageUrl}}/{{companyId}}/{{product.image}}" class="img-fluid">
        </div>
    </div>

    <section class="descricao-produto">
        <h5>{{product.name}}</h5>
        <p>{{product.desc}}</p>
    </section>

    <section class="grupos">
        <div class="g1" *ngFor="let group of product.group">
            <h5>{{group.name}}</h5>
            <hr>
            <div class="row linha-grupo" *ngFor="let complement of group.complement">
                <div class="desc col-7">
                    <span class="">{{complement.name}}<span *ngIf="complement.price > 0"> (+ R$ {{complement.price | number : '1.2-2'}})</span></span>
                </div>
                <div class="qtd col-5">
                    <i (click) = "changeQtdComplementItem($event, 0)" class="fa fa-minus diminuir-qtd" aria-hidden="true" [attr.data-group]="group.id" [attr.data-complement]="complement.id"></i>
                    <span class="qtd-total">{{complement.qtdTotal}}</span>
                    <i (click) = "changeQtdComplementItem($event, 1)" class="fa fa-plus aumentar-qtd" aria-hidden="true" [attr.data-group]="group.id" [attr.data-complement]="complement.id"></i>
                </div>
            </div>
        </div>
    </section>
    <section class="obs">
        <h5>Tem algum pedido especial?</h5>
        <textarea (change)="setObs($event)" id="obs" name="observacao" rows="3" class="form-control"></textarea>
    </section>
</div>

<app-c-card-footer [product]="product"></app-c-card-footer>