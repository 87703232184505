import { Component, OnInit } from '@angular/core';
import { Product } from '../models/Product';
import { Order } from '../models/Order';
import { Router } from '@angular/router';

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.component.html',
  styleUrls: ['./carrinho.component.scss']
})
export class CarrinhoComponent implements OnInit {
  _order : Order;
  localStorage = window.localStorage;
  constructor(private router: Router) { }

  ngOnInit(): void {
    let order = localStorage.getItem('order');
    let jsonObj: any = JSON.parse(order);
    this._order =  <Order>jsonObj;
  }

  apagarItem(index : number){
    this._order.products.splice(index, 1);
    let jsonObj: any = JSON.stringify(this._order);
    localStorage.setItem('order', jsonObj);

    if(this._order.products.length == 0){
      this.router.navigate(['/']);
    }
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  getTotalProduct(product : Product) : number{
    let totalProduto = product.price;
    let totalGeral = 0;

    product.group.forEach(group => {
      group.complement.forEach(complement => {
         if(complement.qtdTotal > 0){
          totalGeral += complement.qtdTotal * complement.price;
         }
      });
    });
    return totalProduto + totalGeral;
  }
}
