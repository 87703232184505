<div [ngClass]="getClass()">
    <div class="row margem-r">
        <div class="col-2 cols-menu text-center">
            <a *ngIf="isHome" href="/" style="color: #FFF;">
                <i class="fa fa-home fa-2x" aria-hidden="true"></i>
            </a>
            <i *ngIf="!isHome" onclick="function hi(){ window.history.back()};hi()"
                class="fa fa-arrow-left fa-2x btn-back" aria-hidden="true"></i>
        </div>

        <div class="col-8 cols-menu text-center">
            <b>LA CASA DE BURGER</b>
        </div>

        <div class="col-2 cols-menu text-center">
            <a style="color: #FFF;" href="https://api.whatsapp.com/send?phone=+5516991017754"><i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i></a>
        </div>
    </div>
</div>