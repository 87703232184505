<app-c-header></app-c-header>
<section>
  <div class="container">
    <h5 class="titulo-checkout">Finalizar Pedido</h5>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit(formGroup.value)">

      <div class="row">
        <div class="col-12">
          <label for="nome-completo">Nome:</label>
          <input type="text" class="form-control" id="nome-completo" formControlName="Name">

          <div class="row">
            <div class="col-6">
              <label for="celular">Celular:</label>
              <input type="text" class="form-control" id="celular" formControlName="Celular" mask="(00) 00000-0000">
            </div>
            <div class="col-6">
              <label for="cep">CEP:</label>
              <input type="text" class="form-control" id="cep" formControlName="Cep" mask="00000-000">
            </div>
          </div>
          <label for="rua">Rua:</label>
          <input type="text" class="form-control" id="rua" formControlName="Rua">
        </div>

        <div class="col-4">
          <label for="numero">Numero:</label>
          <input type="text" class="form-control" id="numero" formControlName="Numero">
        </div>
        <div class="col-8">
          <label for="numero">Complemento:</label>
          <input type="text" class="form-control" id="complemento" formControlName="Complemento">
        </div>
        <div class="col-12">
          <label class="my-1 mr-2" for="pagamento">Pagamento</label>
          <select class="custom-select my-1 mr-sm-2" id="pagamento" formControlName="Pagamento">
            <option selected>Forma de Pagamento</option>
            <option value="1">Dinheiro</option>
            <option value="2">Cartão</option>
          </select>

        </div>

        <div class="col-12">
          <div *ngIf="!sucesso" class="alerta-erro alert alert-danger text-center" role="alert">
            Todos os campos são obrigatórios.
          </div>
          <button type="submit" class="btn btn-success enviar">
            <i aria-hidden="true" class="fa fa-whatsapp fa-1x"></i> Enviar Pedido</button>
        </div>
      </div>
    </form>
    <!-- 
        <form [formGroup]="formGroup">
            <div class="form-group">
              <label for="name">Full name</label>
              <input type="text" class="form-control" id="name" 
                placeholder="Enter full name" formControlName="name" />
            </div>
        
            <div class="form-group">
              <label for="name">Email address</label>
              <input type="email" class="form-control" id="email" 
                placeholder="Enter email" formControlName="email" />
            </div>
        
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" 
                id="terms" formControlName="terms" />
              <label class="form-check-label" for="terms">Agree to terms and conditions?</label>
            </div>
        
            <button class="btn btn-primary" type="submit">Submit Form</button>
          </form>
          <p (click)="onSubmit(formGroup.value)">teste</p> -->
  </div>
</section>